import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";

export default function ScreenSize() {
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }

    useEffect(() => {
        window.addEventListener('resize', detectSize)
    
        return() => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimension])

    
    if (windowDimension.winWidth < windowDimension.winHeight) {
        return(
            window.location.replace('https://m.animalfree.co')
        );
    } 

    else
    return(
        window.location.replace('https://www.animalfree.co')
    );

    
}