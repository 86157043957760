import React, {useState} from 'react';
import Home from './views/Home.js';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";

function App() {
  const [searchResults, setSearchResults] = useState()
  const [checkFood, setCheckFood] = useState()

  const getSearchResults = (results) => {
    setSearchResults(results)
    window.sessionStorage.setItem('results', JSON.stringify(results));
  }

  const getCheckFood = (food) => {
    setCheckFood(food)
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className='container'>
          <Routes>
            <Route path='/' element={<Home></Home>}></Route>
          </Routes>
        </div>
      </header>
    </div>
  );
}

export default App;
